import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { useStore } from '@/store'
import Crypoto from '@/utils/CryptoJS'

const store = useStore()
const cry = new Crypoto()

// 公共路由
const constantRoutes: Array<RouteRecordRaw> = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/login.vue'),
  //   meta: {
  //     title: '登录',
  //     tagView: false
  //   }
  // },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index.vue')
      },
      {
        path: 'document/list',
        name: 'documentList',
        component: () => import('@/views/document/list.vue')
      },
      {
        path: 'document/details',
        name: 'documentDetails',
        component: () => import('@/views/document/details.vue')
      },
      {
        path: 'hotIssue/list',
        name: 'hotIssueList',
        component: () => import('@/views/hotIssue/list.vue')
      },
      {
        path: 'hotIssue/details',
        name: 'hotIssueDetails',
        component: () => import('@/views/hotIssue/details.vue')
      },
      {
        path: 'search/list',
        name: 'searchList',
        component: () => import('@/views/search/list.vue')
      },
      {
        path: 'feedback',
        name: 'feedback',
        component: () => import('@/views/feedback/index.vue')
      }
    ]
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue')
  },
  {
    path: '/notEnabled',
    name: 'notEnabled',
    component: () => import('@/views/notEnabled.vue')
  },
  { path: '/:catchAll(.*)', redirect: '/404' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes
})

// progress样式
NProgress.configure({ easing: 'ease', showSpinner: false })
router.beforeEach(async (to, from, next) => {
  NProgress.start()

  // 页面返回顶部
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0

  try {
    // 解析加密参数
    const dataItem: any = to.query.dataItem
    // console.log('dataItem', dataItem)
    const dataQuery = dataItem && cry.decrypt(dataItem)
    // console.log('dataQuery', dataQuery)

    if (to.path === '/404' || to.path === '/notEnabled') {
      next()
    } else {
      // 没有带参数或者解密失败的
      if (!dataItem || !dataQuery) {
        next({
          path: '/notEnabled'
        })
      } else {
        const encryptData = dataItem
        const decryptData = JSON.parse(dataQuery)

        // 设置网页标题
        document.title = decryptData.name + '-帮助中心'

        // 记录跳转过来的code，和用户资料
        store.commit('app/setDataQuery', { encryptData, decryptData })
        next()
      }
    }
  } catch (e) {
    next({
      path: '/notEnabled'
    })
  }
})
router.afterEach(() => {
  NProgress.done()
})

export default router
