
import { computed, defineComponent, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'ImgBox',
  props: {
    // 链接
    src: {
      type: String,
      required: true
    },
    // 原生 alt
    alt: {
      type: String,
      default: ''
    },
    // 宽度
    width: {
      type: [Number, String],
      default: 150
    },
    // 高度
    height: {
      type: [Number, String],
      default: 150
    },
    // 是否开启动画效果
    ifAnimation: {
      type: Boolean,
      default: true
    },
    // 是否开启懒加载
    lazy: {
      type: Boolean,
      default: false
    },
    // 适应容器框
    fit: {
      type: String,
      default: 'cover'
    },
    // 设置图片预览的 z-index
    zIndex: {
      type: Number,
      default: 2000
    },
    // 是否可点击预览
    ifOnShow: {
      type: Boolean,
      default: true
    },
    // 预览时多图片集合
    srcList: {
      type: Array,
      default: null
    }
  },
  setup (props) {
    const store = useStore()

    const imgRef = ref()

    const data: any = reactive({
      // 处理单图链接
      imgUrl: computed(() => {
        return data.formatUrl(props.src)
      }),
      // 处理多图链接
      srcListUrl: computed(() => {
        if (props.srcList) {
          const newArr = []
          for (const i of props.srcList) {
            newArr.push(data.formatUrl(i))
          }
          return newArr
        } else {
          // 如果没有多图那么默认就是自己
          return [data.imgUrl]
        }
      }),

      // 如果线上地址没带域名就补充
      formatUrl (src: string) {
        let url = ''
        const imgBaseUrl = store.state.app.domainUrl

        if (/(http:|https:|\/img)/.test(src)) {
          url = src
        } else {
          url = imgBaseUrl + '/' + src
        }
        return url
      },

      // 被动调起大图预览
      onPreview () {
        imgRef.value.clickHandler()
      }
    })

    return {
      ...toRefs(data),
      imgRef
    }
  }
})
