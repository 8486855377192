import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import NProgress from 'nprogress'
import Router from '@/router'
import appStore from '@/store/modules/app'

/* 配置请求域名 */
export const baseURL = 'https://project-api.ozkoalas.cn'

/* 创建axios的一个实例 */
const instance = axios.create({
  baseURL,
  // baseURL: '/http', // 配置跨域
  timeout: 15000, // 请求超时毫秒数
  method: 'post'
})

/* 请求拦截器 */
instance.interceptors.request.use(
  (config: AxiosRequestConfig | any) => {
    // 进度条开启
    NProgress.start()

    // 根据code请求
    const code = appStore.state.code
    // console.log('code', code)
    if (code) {
      config.headers.code = code
    }

    return config
  },
  (error) => {
    // 抛出服务器响应错误
    return Promise.reject(error)
  }
)

/* 响应拦截器 */
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    // 进度条关闭
    NProgress.done()

    const res = response.data
    // console.log('请求结果', res)

    // 判断服务器返回的数据
    if (res.code && res.code !== 1) {
      ElMessage({
        message: res.msg,
        type: 'error',
        duration: 3 * 1000
      })

      // 查无此项目 -90002；项目已被禁用 -90003
      if (res.code === -90002 || res.code === -90003) {
        Router.push(`/notEnabled?title=${res.msg}`)
      }

      return Promise.reject(res.msg)
    } else {
      return response.data
    }
  },
  (error) => {
    // 进度条关闭
    NProgress.done()

    // 抛出服务器响应错误
    ElMessage({
      message: error,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

/* 后端数据结构定义 */
export interface HttpResponse {
  [key: string]: any
}

export default instance
