/*
 * @Description: 页面内容
 */
import { baseURL } from '@/api/request'

interface stateType {
  domainUrl: any
  code: string | null
  encryptData: any
  decryptData: any
}
const state = {
  // 域名
  domainUrl: baseURL,
  code: '',
  encryptData: null,
  decryptData: null
}

const getters = {}

const actions = {}

const mutations = {
  setDataQuery (state: stateType, { encryptData, decryptData }: any): void {
    state.encryptData = encryptData

    state.code = decryptData.code
    state.decryptData = decryptData
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
