
import { defineComponent, nextTick, onBeforeMount, onBeforeUnmount, onDeactivated, onMounted, reactive, ref, toRefs, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'NavBox',
  props: {
    selectedItem: {
      type: Number
    },
    ifHome: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    // onBeforeMount(() => {})

    const navBoxRef = ref()

    // 导入mitt
    const mitter: any = inject('mitter')

    onMounted(() => {
      data.navBoxTop = navBoxRef.value.offsetTop
      // console.log('navBoxTop', data.navBoxTop)

      if (props.ifHome) {
        const documentBoxTop = (document.getElementById('documentBox') as HTMLInputElement).offsetTop - 180
        const hotIssueBoxTop = (document.getElementById('hotIssueBox') as HTMLInputElement).offsetTop - 180
        // console.log('documentBoxTop', documentBoxTop)
        // console.log('hotIssueBoxTop', hotIssueBoxTop)
        data.documentBoxTop = documentBoxTop
        data.hotIssueBoxTop = hotIssueBoxTop
      }

      props.ifHome && window.addEventListener('scroll', data.handleScroll, true)

      // 其他页面跳转回来定位
      nextTick(() => {
        const { type } = route.query
        if (Number(type) && route.path === '/index') {
          window.scrollTo({
            top: Number(type) === 0 ? data.documentBoxTop : data.hotIssueBoxTop,
            behavior: 'smooth'
          })
        }
      })
    })

    const data: any = reactive({
      navList: [
        {
          icon: require('@/assets/images/operationDocument.png'),
          selectedIcon: require('@/assets/images/operationDocument-c.png'),
          title: '操作文档'
        },
        {
          icon: require('@/assets/images/hotIssue.png'),
          selectedIcon: require('@/assets/images/hotIssue-c.png'),
          title: '热门问题'
        },
        {
          icon: require('@/assets/images/feedback.png'),
          selectedIcon: require('@/assets/images/feedback-c.png'),
          title: '意见反馈'
        }
      ],
      navItemPosition: '',
      navBoxTop: null,
      documentBoxTop: null,
      hotIssueBoxTop: null,
      encryptData: store.state.app.encryptData,

      // 滚动监听
      handleScroll () {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        // console.log('scrollTop', scrollTop)

        if (scrollTop > data.navBoxTop - 20) {
          data.navItemPosition = 'fixed'
        } else {
          data.navItemPosition = 'relative'
        }

        if (props.ifHome) {
          if (scrollTop <= data.documentBoxTop || scrollTop <= data.hotIssueBoxTop - 100) {
            emit('update:selectedItem', 0)
          } else {
            emit('update:selectedItem', 1)
          }
        }
      },

      // 点击事件
      onItem (index: number) {
        // console.log('index', index)
        // console.log('ifHome', props.ifHome)

        switch (index) {
          case 0:
            if (props.ifHome) {
              window.scrollTo({
                top: data.documentBoxTop,
                behavior: 'smooth'
              })
            } else {
              router.push(`/index?dataItem=${data.encryptData}&type=0`)
              // mitt推送清空搜索内容
              mitter.emit('emptySearchVal')
            }
            break

          case 1:
            if (props.ifHome) {
              window.scrollTo({
                top: data.hotIssueBoxTop,
                behavior: 'smooth'
              })
            } else {
              router.push(`/index?dataItem=${data.encryptData}&type=1`)
              // mitt推送清空搜索内容
              mitter.emit('emptySearchVal')
            }
            break

          default:
            router.push(`/feedback?dataItem=${data.encryptData}`)
            // mitt推送清空搜索内容
            mitter.emit('emptySearchVal')
            break
        }
      }
    })

    onBeforeUnmount(() => {
      // 离开该页面需要移除这个监听的事件，不然会报错
      props.ifHome && window.removeEventListener('scroll', data.handleScroll)
    })

    return {
      ...toRefs(data),
      navBoxRef
    }
  }
})
