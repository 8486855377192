import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-041afe50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "navBoxRef" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "navItem_icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "navItem_title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "layoutBox navBox",
      style: _normalizeStyle({ position: _ctx.navItemPosition })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["navItem", { navItemSelected: _ctx.selectedItem === index }]),
          key: index,
          onClick: ($event: any) => (_ctx.onItem(index))
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.selectedItem === index ? item.selectedIcon : item.icon
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1)
        ], 10, _hoisted_2))
      }), 128))
    ], 4),
    _createElementVNode("div", {
      style: _normalizeStyle({ height: _ctx.navItemPosition === 'fixed' ? '130px' : '0' })
    }, null, 4)
  ], 512))
}