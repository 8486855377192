import { createStore } from 'vuex'

// 自动注册
const modules: any = {}
const requireModule = require.context('./modules', false, /.ts$/)
requireModule.keys().forEach((fileName) => {
  modules[fileName.slice(2, -3)] = requireModule(fileName).default
})
// console.log('modules', modules)

export const store = createStore({
  modules
})

// 导出全局vuex
export function useStore (): any {
  return store as any
}
