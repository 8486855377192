
import { computed, defineComponent, reactive, toRefs, onMounted, inject, onBeforeUnmount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Layout',
  components: {},
  setup () {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    // 导入mitt
    const mitter: any = inject('mitter')

    onMounted(() => {
      // 监听订阅推送
      mitter.on('emptySearchVal', () => {
        console.log('订阅触发')
        data.keyWord = ''
      })
    })

    onBeforeUnmount(() => {
      // 移除订阅推送
      mitter.off('emptySearchVal')
    })

    const data = reactive({
      keyWord: '',
      encryptData: store.state.app.encryptData,
      decryptData: store.state.app.decryptData,

      // 搜索
      onSearchBox () {
        if (!data.keyWord) return ElMessage.warning('请输入内容')
        router.push(`/search/list?dataItem=${data.encryptData}&keyword=${data.keyWord}`)
      }
    })

    return {
      ...toRefs(data)
    }
  }
})
