<!--
 * @Description: 内容块
 *
 * 使用方法:
    <PageMain>内容</PageMain>

    <PageMain title="标题">
      内容
    </PageMain>
-->
<template>
  <div class="page-main">
    <div class="title-container" v-if="title">
      <div>{{ title }}</div>
      <!-- 内容 -->
      <slot name="rightContent" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PageMain',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.page-main {
  position: relative;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0 10px 0 #e0e0e0;

  .title-container {
    width: calc(100% + 40px);
    padding: 14px 20px;
    margin-left: -20px;
    margin-top: -20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
