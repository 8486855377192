import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'

// 导入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIconModules from '@element-plus/icons-vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

// 导入样式重置/全局样式
import './assets/styles/reset.scss'
import './assets/styles/global.scss'

// 导入组件、图标配置文件
import componentsPlugin from './components/autoRegister'
import svgPlugin from '@/assets/icons/svgPlugin'

// 导入全局方法
import globalFun from './utils'

// 导入mitt组件通信
import mitt, { Emitter } from 'mitt'

// 创建APP
const app = createApp(App)

// 挂载全局方法
app.config.globalProperties.$globalFun = globalFun

// 全局组件自动注册
componentsPlugin.keys().forEach((fileName) => {
  // 获取文件中的 default 模块
  const componentConfig = componentsPlugin(fileName).default
  if (/.vue$/.test(fileName)) {
    app.component(componentConfig.name, componentConfig)
  } else {
    app.use(componentConfig)
  }
})

// 统一注册 element-Icon 图标
const ElIconModulesList: any = ElIconModules
for (const iconName in ElIconModulesList) {
  if (Reflect.has(ElIconModulesList, iconName)) {
    const item = ElIconModulesList[iconName]
    app.component(iconName, item)
  }
}

// 配置mitt组件通信
type Events = {
  foo: string;
  bar?: number;
};
const mitter: Emitter<Events> = mitt<Events>()
app.provide('mitter', mitter)

app.use(store)
app.use(router)
app.use(ElementPlus, { locale: zhCn })
app.use(svgPlugin)
app.mount('#app')
